/* NAVBAR  STYLE */
.navbar li {
  margin: 0px;
}
.navbar li:last-child {
  margin: 0;
}

.navbar li a {
  /* color: rgb(11, 109, 161); */
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", Sans-serif;
  color: #fff;
  transition: all ease-in 0.2s;
  padding: 9px 18px;
  border-radius: 4px;
}

.nav-active {
  background-color: rgb(0, 0, 56);
  box-shadow: 5px -1px 8px -6px grey;
  transition: 0.3s ease-in-out;
  z-index: 100;
}

.notificationCounter {
  position: absolute;
  background: rgb(184, 13, 13);
  line-height: 100%;
  padding: 4px;
  font-size: 14px;
  top: -15px;
  right: 0px;
  border-radius: 25%;
  text-align: center;
  display: flex;
  display: none;
  color: #fff;
  font-weight: 600;
}

.nav-button {
  background-color: #ff6700;
  padding: 0 34px;
}
.nav-button:hover {
  background-color: #ff6700;
}
.menu-icon {
  font-size: 36px;
  color: #fff;
}
/* RESPONSIVE STYLE */
@media only screen and (max-width: 768px) {
  .navbar li {
    margin: 18px 15px;
  }
  .navbar li a {
    /* color: rgb(11, 109, 161); */
    color: #000;
  }
}
@media only screen and (max-width: 1023px) {
  .navbar li {
    margin: 18px 15px;
  }
  .notificationCounter {
    top: -15px;
    right: 425px;
  }
}

@media only screen and (max-width: 822px) {
  .notificationCounter {
    top: -15px;
    right: 320px;
  }
}

@media only screen and (max-width: 610px) {
  .notificationCounter {
    top: -15px;
    right: 220px;
  }
}

@media only screen and (max-width: 409px) {
  .notificationCounter {
    top: -15px;
    right: 125px;
  }
}
