.bg-img1 {
  background: url(../../../../../Images/Banner/banner-bg.PNG);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}
.text-color {
  color: rgb(90, 89, 89);
}
.btn-bg {
  background-color: #ff6700;
  color: #fff;
  padding: 22px 45px;
}
